import React from 'react';

export default function () {
	return <div>
		<h2 className="is-size-4">Информация о домене</h2>
		<p>WHOIS (англ. "Who is?" — "Кто такой?") — протокол, благодаря которому можно получить информацио о доменном
			имени или об IP-адресе.</p>

		<p>С помощью WHOIS можно узнать, занят ли домен, а также выяснить контактные данные владельца домена, дату
			создания, дату окончания регистрации и многое другое.</p>

		<p>Вся эта информация является публичной, однако некоторые регистраторы позволяют скрывать прямые контакты
			владельца домена. В этом случае будут указаны контакты компании-регистратора.</p>
	</div>;
}