import React from 'react';

export default function ruDesc () {
	return <div>
		<h2 className="is-size-4">Соответствие портов и протоколов</h2>
		<p>Данный сервис позволяет сопоставить номер порта одному или нескольким сетевым протоколам, в рамках которых
			данный порт обычно используется. Сервис полезен, когда неизвестна причина, по которой открыт порт.</p>

		<p>Для поиска используется официальная база портов и протоколов от IANA (Internet Assigned Numbers Authority).
			IANA – организация, управляющая параметрами протоколов интернета, а также пространствами IP адресов и
			доменами верхнего уровня.</p>

		<h3 className="is-size-5">Основные диапазоны портов</h3>
		<p>Весь диапазон номеров портов (от 0 до 65535) разделен на три категории.

		</p>

		<strong>0 — 1023 Общеизвестные порты</strong>
		<p>Номера зарезервированы IANA для системных процессов или сетевых программ с административными правами. Порты
			из данной категории не должны использоваться без регистрации у IANA.</p>

		<strong>1024 — 49151 Зарегистрированные порты</strong>
		<p>Порты, зарегистрированные для использования обычными программами и протоколами прикладного уровня. Это
			категория портов наиболее популярна для коммерческого программного обеспечения. Также требуется регистрация
			на использование какого-либо порта.</p>

		<strong>49152 — 65535 Динамические порты</strong>
		<p>Предназначены для свободного, но временного использования. Регистрация портов этой категории невозможна.</p>
	</div>;
}