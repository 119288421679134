import React from 'react';

export default function () {
	return <div>
		<h2 className="is-size-4">Заголовки ответа</h2>

		<p>Данный сервис позволяет просмотреть заголовки ответа сервера и узнать код состояния HTTP. Это информация,
			которую сервер отправляет в ответ на HTTP запрос.</p>
		<p>Заголовки (headers) содержат информацию о результате запроса браузера, о технологиях, используемых на
			сервере, размере контента, данные cookies. Также зачастую в заголовках ответа сервера передаются инструкции
			поведения для клиента (браузера).</p>

		<p>По заголовкам вполне можно увидеть, какой именно веб-сервер (Apache, nginx и др.) и операционная система
			применяются на сервере. Также можно увидеть время, по которому живет сервер.</p>
	</div>;
}